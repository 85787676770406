export interface Config {
  links: {
    money: string;
    letter: string;
    fsinet: string;
    atlas: string;
    window: string;
  };
  baseUrl: string;
  basePath: string;
  reCaptcha: {
    siteKey: string;
  };
}

const env: string = process.env.ENV || 'production';
const isDevelopment: boolean = env === 'development' || env === 'local';
const isProduction: boolean = env === 'production';
const variables = isDevelopment ? process.env : (window as any)._env_;

export const config: Config = {
  links: {
    money: isProduction ? 'https://f-money.ru' : variables.MONEY_URL,
    letter: isProduction ? 'https://f-pismo.ru/new/main' : variables.LETTER_URL,
    fsinet: isProduction ? 'https://fsinet.ru' : variables.FSINET_URL,
    atlas: isProduction ? 'https://f-atlas.ru' : variables.ATLAS_URL,
    window: isProduction ? 'https://f-okno.ru' : variables.WINDOW_URL,
  },
  baseUrl: isProduction ? '/store-backend/rest' : variables.BASE_URL,
  basePath: isProduction ? '/store' : variables.BASE_PATH,
  reCaptcha: {
    siteKey: isProduction
      ? '6LfMxC4iAAAAAJIzab0E_tpQi1LYQYartyg41cFk'
      : variables.RECAPTCHA_SITE_KEY,
  },
};

export const GET_OFFER_URL = config.baseUrl + '/static/offer';
